/* page style */
.page-content {
  margin: 20px;
}

/* modals */
/* 90% width modal style */
.modal-dialog.modal-90w {
  max-width: 90vw;
}

.modal-content {
  background-color: #414347;
  color: #8a959f;
}

.jumbotron {
  background-color: #292d35;
}

.input-group-text {
  color: #8a959f;
  background-color: #161719;
  border: none;
}
